import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { useGroupFormSchema } from "../../lib/formSchema/registrationFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MemberForm from "./MemberForm";
import { useTranslation } from "react-i18next";

const Note = (props) => (
  <Typography
    variant="subtitle1"
    color="textSecondary"
    align="center"
    sx={{ marginBottom: "20px" }}
  >
    {props.t("note")}
  </Typography>
);

const GroupForm = () => {
  const { t } = useTranslation("Registration");
  const groupFormSchema = useGroupFormSchema();
  const [isPreview, setIsPreview] = useState({
    bool: false,
    members: 0,
  });
  const {
    setValue,
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(groupFormSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      city: "",
      age: "",
      gender: "",
      dateOfArrival: "",
      dateOfDeparture: "",
      numberOfMembers: 1,
      members: [],
    },
  });

  const { remove } = useFieldArray({
    control,
    name: "members",
  });

  const handleChange = () => {
    const count = watch("numberOfMembers");
    setIsPreview({
      bool: true,
      members: count,
    });
  };

  const removeParticipant = (index) => {
    setIsPreview({
      bool: false,
    });
    remove(index);
    setValue("numberOfMembers", watch("numberOfMembers") - 1);
    if (watch("numberOfMembers") >= 2) {
      setTimeout(() => {
        setIsPreview({
          bool: true,
          members: watch("numberOfMembers"),
        });
      }, 4);
    }
  };

  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  const onSubmit = async (values) => {
    if (watch("members").length < watch("numberOfMembers") - 1) {
      toast.error(t("member-update"), {
        duration: 3000,
        position: "top-right",
      });
    }
    if (values.numberOfMembers !== values.members.length + 1) {
      values.members = values.members.filter(
        (_, index) => index < values.members.length - 1
      );
    }
    if (dayjs(values.dateOfArrival).isAfter(dayjs(values.dateOfDeparture))) {
      toast.error(t("date-error"));
      return;
    }
    const lowercaseValues = {
      ...values,
      first_name: values.first_name.toLowerCase(),
      last_name: values.last_name.toLowerCase(),
      email: values.email.toLowerCase(),
      city: values.city.toLowerCase(),
      members: values.members.map((member) => ({
        ...member,
        first_name: member.first_name.toLowerCase(),
        last_name: member.last_name.toLowerCase(),
      })),
    };
    fetch(
      "https://3i11a61k0e.execute-api.ap-south-1.amazonaws.com/dev1/storeData",
      {
        mode: "cors",
        method: "POST",
        headers: {
          "x-api-key": "C9yfSdhFSq24mNNSeQZMq8ybeYYqTwsi83g6jKLh",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ values: lowercaseValues }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 200) {
          toast.success(t("form-submit"), { duration: 5000 });
          setTimeout(() => {
            window.location.href = "/oct2024";
          }, 3000); // Redirect after 3 seconds
        } else if (data.status === 409) {
          const alreadyRegisteredMembers = data.already_registered_members.map(
            (member) =>
              `${member.first_name} ${member.last_name} (${member.age}, ${member.gender}, ${member.city})`
          );
          toast.error(
            `${t("already-registered")} ${alreadyRegisteredMembers.join(", ")}`,
            {
              duration: 10000,
              position: "top-right",
            }
          );
        } else throw new Error(data.message);
      })
      .catch((error) => {
        toast.error(
          <div>
            Form submission failed. Kindly reach out to us at the phone numbers
            mentioned &nbsp;
            <Link
              to="/oct2024"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              here.
            </Link>
          </div>,
          { duration: 5000 }
        );
      });
  };

  return (
    <Box sx={{ padding: "1.5% 5% 5% 5%" }}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <Typography variant="h5" gutterBottom>
          {t("registration-form")}
        </Typography>
        <Note t={t}/>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              label={t("first-name")}
              name="first_name"
              {...register("first_name")}
              required
              fullWidth
            />
            <p error={!!errors.first_name} style={{ color: "red" }}>
              {errors.first_name?.message}
            </p>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t("last-name")}
              name="last_name"
              {...register("last_name")}
              required
              fullWidth
            />
            <p error={!!errors.last_name} style={{ color: "red" }}>
              {errors.last_name?.message}
            </p>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t("email")}
              type="email"
              name="email"
              {...register("email")}
              fullWidth
            />
            <p error={!!errors.email} style={{ color: "red" }}>
              {errors.email?.message}
            </p>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t("phone")}
              type="tel"
              name="phone"
              {...register("phone")}
              required
              fullWidth
            />
            <p error={!!errors.phone} style={{ color: "red" }}>
              {errors.phone?.message}
            </p>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t("age")}
              type="number"
              name="age"
              {...register("age")}
              required
              fullWidth
            />
            <p error={!!errors.age} style={{ color: "red" }}>
              {errors.age?.message}
            </p>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="gender">{t("gender")} *</InputLabel>
              <Select
                name="gender"
                {...register("gender")}
                required
                labelId="gender"
                label={t("gender")}
              >
                <MenuItem value="Male">{t("male")}</MenuItem>
                <MenuItem value="Female">{t("female")}</MenuItem>
                <MenuItem value="Other">{t("others")}</MenuItem>
              </Select>
            </FormControl>
            <p error={!!errors.gender} style={{ color: "red" }}>
              {errors.gender?.message}
            </p>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t("city")}
              name="city"
              {...register("city")}
              required
              fullWidth
            />
            <p error={!!errors.city} style={{ color: "red" }}>
              {errors.city?.message}
            </p>
          </Grid>
          <Grid item xs={6}>
            <DateTimePicker
              label={t("date-and-time-of-arrival")}
              name="dateOfArrival"
              onChange={(e) => {
                if (!e) return;
                setValue("dateOfArrival", e["$d"]);
              }}
              sx={{ width: "100%" }}
            />
            <p error={!!errors.dateOfArrival} style={{ color: "red" }}>
              {errors.dateOfArrival?.message}
            </p>
          </Grid>
          <Grid item xs={6}>
            <DateTimePicker
              label={t("date-and-time-of-departure")}
              name="dateOfDeparture"
              onChange={(e) => {
                if (!e) return;
                setValue("dateOfDeparture", e["$d"]);
              }}
              required
              sx={{ width: "100%" }}
            />
            <p error={!!errors.dateOfDeparture} style={{ color: "red" }}>
              {errors.dateOfDeparture?.message}
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <div>
              <TextField
                label={t("number-of-members")}
                type="number"
                name="numberOfMembers"
                required
                sx={{ width: "20vw" }}
                {...register("numberOfMembers")}
              />
              <p error={!!errors.numberOfMembers} style={{ color: "red" }}>
                {errors.numberOfMembers?.message}
              </p>
            </div>
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: "10vw" }}
              onClick={handleChange}
              disabled={watch("numberOfMembers") < 1}
            >
              {t("update")}
            </Button>
          </Grid>
        </Grid>
        {isPreview.bool &&
          Array.from({ length: isPreview.members - 1 }).map((_, index) => (
            <Box
              key={index}
              sx={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "10px",
                position: "relative",
                marginBottom: "10px",
              }}
            >
              <MemberForm control={control} index={index} errors={errors} t={t}/>
              <Button
                variant="contained"
                color="error"
                onClick={() => removeParticipant(index)}
                sx={{ position: "absolute", top: "-10px", right: "-10px" }}
              >
                {t("remove")}
              </Button>
            </Box>
          ))}
        {(watch("numberOfMembers") < 2 || isPreview.bool) && (
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            sx={{ width: "25vw" }}
          >
            {t("submit")}
          </Button>
        )}
        <Toaster />
      </Box>
    </Box>
  );
};

export default GroupForm;
